@import "@/styles/new-variables";

.notificationsIcon {
  display: flex;
  padding: 0.375rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid $transparent-white-10;
  transition-property: border-color color;
  transition-duration: 300ms;
  cursor: pointer;

  &:hover {
    border-color: $primary;
    color: $primary;
  }
}

.slideEnter {
  transform: translateX(100%);
}

.slideEnterActive {
  transform: translateX(0);
  transition: transform 0.3s;
}

.slideExit {
  transform: translateX(0);
}

.slideExitActive {
  transform: translateX(100%);
  transition: transform 0.3s;
}

