@import '@/styles/functions';
@import '@/styles/new-variables';

$image-radius: calc(1rem - 2px);

.movieTheaterCard {
  position: relative;
  height: 100%;
  border-radius: 1rem;
  background: $black;
  backdrop-filter: blur(15px);
  border: 2px solid $transparent-grey-10;

  .imageContainer {
    .movieImage {
      border-radius: $image-radius;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
    }

    .movieTheaterCardBody {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: toRem(20) toRem(17) toRem(24);

      .contentOriginBadge {
        z-index: 1;
        display: flex;
        justify-content: center;
      }
    }

    .movieCardOverlay {
      background-color: $transparent-black-60;
      backdrop-filter: blur(5px);

      .overlayCardBody {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        padding: 2rem 1rem 1rem;

        @media (min-width: 425px) {
          padding: 4rem 1rem 1rem;
        }

        .contentCountWrapper {
          flex: 4;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;

          @media (min-width: 425px) {
            gap: 1rem;
          }

          .contentCount {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .primary {
              display: inline-flex;
              background-image: $main-gradient;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .number {
              font-size: toRem(22);
              line-height: toRem(32.73);

              @media (min-width: 425px) {
                font-size: toRem(32);
                line-height: toRem(38.73);
              }
            }
            .title {
              font-size: toRem(16);
              line-height: toRem(19.2);

              @media (min-width: 425px) {
                font-size: toRem(20);
                line-height: toRem(24.2);
              }
            }
          }
        }

        .line {
          width: 100%;
          height: toRem(1);
          background: $transparent-white-10;
        }
      }
    }

    .contentInfo {
      display: flex;
      justify-content: center;

      .btn {
        display: inline-flex;
        height: auto;
        width: auto;
      }
    }
  }
}
