@import "@/styles/variables";

.cardImageWrapper {
  width: 100%;
  display: block;
  position: relative;
  // box-shadow: $image-box-shadow;
  text-align: inherit;
  overflow: hidden;
  transform-origin: bottom;
  transition: all 0.2s ease-in;
}
