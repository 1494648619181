@import '@/styles/variables';
@import '@/styles/new-variables';
@import '@/styles/functions';

.categoryButton {
  position: relative;
  height: 2.75rem;
  width: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid $transparent-white-10;
  transition-property: color, border-color;
  transition-duration: 300ms;

  &:hover {
    color: $signature-color;
    border-color: $primary;
  }
}

.backdropSidebar {
  z-index: 26;
}

.active {
  color: $signature-color;
  z-index: 27;
  border-color: $primary;
}

.categoryContainer {
  position: absolute;
  width: 100%;
  top: $desktop-header-height;
  left: 0;
  display: flex;
  justify-content: center;

  .content {
    z-index: 30;
    padding: 1.5rem;
    width: 80vw;
    flex-shrink: 0;
    border-radius: 1.5rem;
    border: 1px solid $transparent-white-6;
    background: $main-black;
    backdrop-filter: blur(15px);
    max-height: 80vh;
    overflow-y: auto;

    @media (min-width: get-breakpoint-width('xxl')) {
      width: toRem(1340);
    }
  }
}

.categoryWrapper {
  position: relative;
}
