@import '@/styles/variables';

.searchFormWrapper {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 100%;

  .categoryButton {
    position: relative;
    height: 2.75rem;
    width: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid $transparent-white-10;
    transition-property: color, border-color;
    transition-duration: 300ms;

    &.active {
      color: $signature-color;
      border-color: $primary;
    }

    &:hover {
      color: $signature-color;
      border-color: $primary;
    }
  }

  .searchForm {
    max-width: 100%;
    width: 100%;
    background: $grey-1;
    border-radius: 0.75rem;
    position: relative;

    & > label {
      margin-bottom: 0;
    }
  }

  .searchInput {
    width: 100%;
    height: 2.75rem;
    display: flex;
    align-items: center;
  }
}
.searchResultsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: toRem(12);
  font-size: toRem(14);
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  color: $grey-4;

  .searchResultsButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0.6rem;
    color: $light-color-1;
    border-radius: 1rem;
    color: $primary;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
}

.toggler {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  display: inline-flex;
  padding: 0.25rem;
  align-items: center;
  border-radius: 50%;
  background: var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));
}

.subtitle {
  padding-top: 1rem;
  color: $grey-4;
  font-size: toRem(14);
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}

.suggestions {
  cursor: pointer;
}
