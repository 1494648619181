@import "@/styles/functions";

.wrapper{
    margin-bottom: toRem(40);
    
    @media (max-width: get-breakpoint-width("md")) {
        margin-bottom: toRem(20);
    }
}

.onlineTvList{
    display: grid;
    gap: toRem(27);
    margin-bottom: toRem(40);
    grid-template-columns: repeat(7, 1fr);
    padding-right: 5px;


    @media (max-width: get-breakpoint-width("xl")) {
        gap: toRem(11);
    }

    @media (max-width: get-breakpoint-width("lg")) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: get-breakpoint-width("md")) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: get-breakpoint-width("sm")) {
        grid-template-columns: repeat(3, 1fr);
    }
}