@import '@/styles/variables';
@import '@/styles/functions';

.desktopMenu {
  display: none;
  visibility: hidden;
  min-height: $desktop-header-height;
  align-items: center;
  position: relative;

  .desktopHeaderLogo {
    width: toRem(177);
    height: 6.25rem;
    align-self: stretch;
    flex-shrink: 0;
  }

  .desktopRightNav {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    gap: 0.625rem;
  }

  .menuContainer {
    flex-grow: 1;
    align-self: stretch;
    position: relative;
  }
}

@media (min-width: 1200px) {
  .desktopMenu {
    display: flex;
    width: 100%;
    visibility: visible;
    gap: 1.5rem;
  }
}
