@import "@/styles/functions";
@import "@/styles/new-variables";

.onlineTvListSlide {
  max-width: calc((100% - 2 * $swiper-gap-xs) / 3);
  margin-right: $swiper-gap-xs;

  @media (min-width: get-breakpoint-width("md")) {
    max-width: calc((100% - 3 * $swiper-gap-md) / 4);
    margin-right: $swiper-gap-md;
  }

  @media (min-width: get-breakpoint-width("lg")) {
    max-width: calc((100% - 4 * $swiper-gap-lg) / 5);
    margin-right: $swiper-gap-lg;
  }

  @media (min-width: get-breakpoint-width("xl")) {
    max-width: calc((100% - 5 * $swiper-gap-xl) / 6);
    margin-right: $swiper-gap-xl;
  }

  @media (min-width: get-breakpoint-width("xxl")) {
    max-width: calc((100% - 6 * $swiper-gap-xxl) / 7);
    margin-right: $swiper-gap-xxl;
  }
}

.sectionHeading {
  display: flex;
  align-items: center;
  gap: toRem(8);

  .icon {
    color: $grey-4;
  }
}
