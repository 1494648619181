@import "@/styles/functions";
@import "@/styles/new-variables";

.subtitle {
  font-size: toRem(11);
  line-height: 130%;
  font-weight: 300;
  color: $transparent-white-50;
  text-align: inherit;
}
