@import "@/styles/functions";
@import "@/styles/new-variables";

.sliderNavigations {
  display: none;
}

@media (min-width: get-breakpoint-width("lg")) {
  .sliderNavigations {
    display: block;
  }
}
