@import '@/styles/functions';
@import '@/styles/new-variables';

%yellowCircle {
  width: toRem(4);
  height: toRem(4);
  border-radius: 50%;
  background: $primary;
  display: inline-block;
  margin-left: toRem(8);
}

.breadcrumbs {
  width: 100%;
  display: flex;
  align-items: center;

  .breadcrumbsList {
    width: 100%;
    display: flex;
    align-items: center;
    gap: toRem(8);
    border-radius: toRem(8);
    background: $grey-1;
    min-height: toRem(32);
    padding: toRem(8) toRem(12);
  }

  .breadcrumbsItem {
    display: flex;
    align-items: center;
    font-size: toRem(12);
    font-weight: 500;
    line-height: 130%;
    transition: color 0.3s;
    color: $grey-4;

    &:hover {
      color: $white;
    }

    &:not(:last-child) {
      &:after {
        content: '';
        @extend %yellowCircle;
      }
    }

    &:last-child {
      .breadcrumbsLink {
        color: $white;
      }
    }
  }
}
