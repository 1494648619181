.country {
  padding: 2rem 0;

  & > * {
    display: block;
  }

  & > :nth-child(2) {
    font-size: 3.125rem;
    font-weight: 600;
  }
}

.moviesPanel {
  padding: 3rem 0;
}
