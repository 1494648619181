@import '@/styles/functions';

$gap: toRem(12);

.movieRelatedSlider {
  margin-top: toRem(12);
  margin-bottom: 2rem;

  @media (min-width: get-breakpoint-width('md')) {
    margin-top: 1rem;
  }
}

.movieRelatedSlide {
  margin-right: $gap;
  max-width: calc((100% - $gap) / 2);

  @media (min-width: get-breakpoint-width('md')) {
    max-width: calc((100% - 2 * $gap) / 3);
  }

  @media (min-width: get-breakpoint-width('lg')) {
    margin-right: toRem(18);
    max-width: calc((100% - 3 * toRem(18)) / 4);
  }

  @media (min-width: get-breakpoint-width('xl')) {
    margin-right: toRem(20);
    max-width: calc((100% - 5 * toRem(20)) / 6);
  }
}
