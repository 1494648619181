@import '@/styles/new-variables';

$border-radius: calc(1rem - 2px);

.movieCard {
  position: relative;
  background: $black;
  border-radius: 1rem;
  border: 2px solid $transparent-grey-10;
  overflow: hidden;

  .skeltonImage {
    position: relative;
    overflow: hidden;
    color: transparent;
    border-radius: $border-radius $border-radius 0 0;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
