@import "@/styles/variables";
@import "@/styles/functions";
@import "@/styles/new-variables";

.userBalanceCard {
  position: relative;
  padding: toRem(20);
  overflow: hidden;
  border-radius: 1rem;
  background: $grey-1;

  .backgroundImage {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .iconWrapper {
    display: flex;
    padding: toRem(9);
    justify-content: center;
    align-items: center;
    border-radius: toRem(10);
    background: linear-gradient(191deg, rgba(255, 255, 77, 0.1) -46.82%, rgba(244, 102, 0, 0.1) 201%);
  }

  .subscriptionPrice {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: $transparent-white-30;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%; /* 20.8px */

    .price {
      color: $white;
      font-size: 24px;
      font-weight: 600;
      line-height: 130%; /* 31.2px */
      padding-right: toRem(3);
    }
  }

  .balanceInfo {
    position: relative;
    display: flex;
    gap: toRem(14);
    z-index: 2;
  }
}
