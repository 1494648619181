@import "@/styles/new-variables";
@import "@/styles/functions";

$radius: toRem(8);

.badgeWrapper {
  display: flex;

  .badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    text-transform: uppercase;
    padding: toRem(6);
    font-size: toRem(12);
    font-weight: 700;

    /* Status */
    &.free {
      color: $white;
      background: linear-gradient(90deg, #34c763 0%, #196130 107.69%);
      // backdrop-filter: blur(10px);
    }

    &.new {
      color: $white;
      background: linear-gradient(90deg, #34c763 0%, #196130 107.69%);
      // backdrop-filter: blur(10px);
    }

    /* Quality */
    &.quality {
      background-color: $primary;
      color: $black;
    }

    &.quality-tv {
      padding: toRem(8) toRem(6);
      border-radius: toRem(8);
      border: 1px solid $transparent-black-20;
      background: $primary;
      // backdrop-filter: blur(5px);
      color: $black;
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 75%;
    }

    /* Position */
    &.left {
      border-radius: 0 $radius $radius 0;
    }

    &.right {
      border-radius: $radius 0 0 $radius;
    }

    &.top {
      border-radius: 0 0 $radius $radius;
    }

    &.bottom {
      border-radius: $radius $radius 0 0;
    }

    &.default {
      border-radius: $radius;
    }
  }
}
