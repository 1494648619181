.slideEnter {
  transform: translateY(100%);
}

.slideEnterActive {
  transform: translateY(0);
  transition: transform 0.3s;
}

.slideExit {
  transform: translateY(0);
}

.slideExitActive {
  transform: translateY(100%);
  transition: transform 0.1s;
}
