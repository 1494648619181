.containerClassName {
  position: relative;
  display: block;
  height: 1.2rem;
  line-height: 1.2rem;
  overflow: hidden;
}

.contentClassName {
  position: absolute;
  white-space: nowrap;
}

.contentIdleClassName {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentEnabledClassName {
  text-align: left;
  will-change: transform;
}

.clonedContentClassName {
  display: inline-block;
}
