@import "@/styles/functions";
@import "@/styles/new-variables";

.viewAllBtn {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: toRem(8);
  height: 100%;
  max-width: toRem(136);

  .icon {
    display: flex;
    width: toRem(64);
    height: toRem(64);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    background: $transparent-white-10;
    color: $white;
    transition: color 300ms;
  }

  .text {
    color: $transparent-white-70;
    // font-family: Inter;
    width: fit-content;
    font-size: toRem(14);
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    transition: color 300ms;
  }

  &:hover {
    .icon,
    .text {
      color: $primary;
    }
  }
}

@media (min-width: get-breakpoint-width("sm")) {
  .viewAllBtn {
    .text {
      font-size: 1rem;
    }
  }
}
