.iconButtonBase {
  align-items: center;

  &:not(.inline) {
    display: flex;
  }
}

.inline {
  display: inline-flex;
}

.disabled {
  opacity: 0.8;
  cursor: no-drop;
}

.iconMarginRight {
  margin-right: 0.5rem;
}
