@import "@/styles/functions";
@import "@/styles/new-variables";

.person{
  position: relative;
  margin-bottom: toRem(40);
  
  &.withPhoto{
    padding-top: 30%;
    padding-bottom: 4%;
  }

  &.skelton{
    background: $grey-10;  
  }

  @media (max-width: get-breakpoint-width("md")) {
    margin-bottom: toRem(20);
  }

  .overlay{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #0F0F12 0%, rgba(15, 15, 18, 0.70) 29.7%, rgba(15, 15, 18, 0.40) 49.9%, rgba(15, 15, 18, 0.88) 79.6%, #0F0F12 100%);    opacity: 0.5;
  }

  .bgImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .personInfo{
    position: relative;
    display: flex;
    align-items: center;
    gap: toRem(28);

    .photoWrapper{
      flex-shrink: 0;
      position: relative;
      width: toRem(124);
      height: toRem(124);
      border-radius: 50%;
      overflow: hidden;
      background: $black;
      border: 2px solid $grey-10;
      backdrop-filter: blur(15px);

      .photo{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .personDetails{
      .fullName{
        font-size: toRem(32);
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 1rem;

      }
      .roles{
        display: flex;
        align-items: center;
        gap: toRem(8);
        .role{
          display: flex;
          align-items: center;
          justify-content: center;
          
          font-size: toRem(12);
          font-weight: 500;
          line-height: 130%; 

          border-radius: toRem(6);
          background: $grey-2;
          min-height: toRem(24);
          padding: 0 toRem(6);
          &.active{
            background: $primary;
            color: $black;
          }
        }
      }
    }
  }
}