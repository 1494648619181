@import "@/styles/functions";
@import "@/styles/new-variables";

.sectionHeading {
  display: flex;
  align-items: center;
  gap: toRem(8);

  .icon {
    color: $grey-4;
  }
}
