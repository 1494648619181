@import "@/styles/variables";

.solidButtonBase {
  display: inline-block;
  font-size: $solid-button-desktop-font-size;
  padding: $solid-button-desktop-padding;
  font-weight: $solid-button-font-weight;
  border-radius: 4px;
  transition: all 0.3s;

  &.signature {
    color: $solid-button-signature-text-color;
    background-color: $solid-button-signature-bg-color;
    border: 1px solid $solid-button-signature-bg-color;
  }

  &.dimmed {
    color: $solid-button-dimmed-text-color;
    background-color: $solid-button-dimmed-bg-color;
    border: 1px solid $solid-button-dimmed-bg-color;
  }

  &.signature:disabled,
  &.dimmed:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.auto {
    width: fit-content;
  }

  &.full {
    width: 100%;
  }

  @media (min-width: 1200px) {
    &.signature:hover {
      color: $solid-button-signature-bg-color;
      background-color: $dark-color-1;
    }

    &.dimmed:hover {
      color: $solid-button-dimmed-bg-color;
      background-color: $light-dimmed-color-2;
    }
  }
}
