@import "@/styles/functions";

$desktop-aspect-ratio: 984 / 552;
$mobile-aspect-ratio: 311 / 311;

.bannerSlide {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: calc(100% / $mobile-aspect-ratio);
  border: toRem(1) solid #ffffff0f;
  border-radius: toRem(20);

  .imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .bannerImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (min-width: get-breakpoint-width("sm")) {
  .bannerSlide {
    padding-bottom: calc(100% / $desktop-aspect-ratio);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: linear-gradient(to right, rgba(15, 15, 18, 1), rgba(15, 15, 18, 0.3));
      z-index: 1;
    }
  }
}
