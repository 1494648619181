@import "@/styles/variables";

.textButtonBase {
  font-size: $text-button-mobile-font-size;
  font-weight: $solid-button-font-weight;
  color: $light-color-1;
  transition: color 0.3s;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  @media (min-width: 1200px) {
    color: $text-button-text-color;
    font-size: $text-button-desktop-font-size;

    &:hover {
      color: $light-color-1;
    }
  }
}
