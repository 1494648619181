@import '@/styles/functions';
@import '@/styles/new-variables';

.actionsButton {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: toRem(12);

  .randomButton {
    background: $transparent-white-10;
    backdrop-filter: blur(10px);

    .randomButtonContent {
      position: relative;

      .spinner {
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translate(-100%, -50%);
      }
    }
  }

  & > a,
  button {
    width: 100%;
  }
}

@media (min-width: get-breakpoint-width('md')) {
  .actionsButton {
    flex-direction: row;
  }
}
