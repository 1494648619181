@import '@/styles/variables';

.remainingDays {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $dark-color-1;
  background-color: $signature-color;
  border-radius: 4px;

  .remainder,
  .remainderText {
    font-weight: bold;
  }

  .remainder {
    font-size: 1.5rem;
  }

  .remainderText {
    font-size: 0.8rem;
  }

  &.reverseOrder {
    flex-direction: column-reverse;
    gap: 0.25rem 0;
  }
}

.description {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 1rem;

  .title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .dateOfExpiry {
    font-size: 0.85rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;

    & span {
      margin-right: 0.5rem;
      margin-bottom: 0.3rem;
      color: $light-dimmed-color-3;
    }
  }
}

@media (min-width: 425px) {
  .dateOfExpiry {
    & span {
      margin-bottom: 0;
    }
  }
}
.deleteButton {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  height: 100%;
  transform: translate(-50%, -50%);
  transition: color 0.3s;

  &:hover {
    color: $danger-color;
  }
}

.errorText {
  font-size: $form-input-error-font-size;
  color: $form-input-error-text-color;
}
