@import '@/styles/functions';
@import '@/styles/new-variables';

.breadcrumbsWrapper {
  margin: 0 0 toRem(32) 0;

  @media (max-width: get-breakpoint-width('lg')) {
    margin: toRem(12) 0;
  }
}
