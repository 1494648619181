@import '@/styles/functions';
@import '@/styles/new-variables';


.content{
    height: calc(100vh - 90px);
    overflow: auto;
}

.imageContainer{
    position: relative;
    padding-bottom: 100%;
    img{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.title{
    color: white;
    font-size: toRem(24);
    font-weight: 600;
    margin-bottom: toRem(12);
}

.info{
    padding: toRem(20);
}

.description{
    color: $grey-5;
    font-size: toRem(14);
    line-height: 140%;
}
