@import "@/styles/new-variables";

.container{
  padding: 0 toRem(20);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: toRem(16);

}

.readAllButton{
    color: #FFF;
    font-weight: 500;
    line-height: 130%; 
    border-radius: 8px;
    border: 1px solid $transparent-white-6;
    background: $transparent-white-6;
    backdrop-filter: blur(10px);
    min-height: toRem(32);
    padding: 0 toRem(8);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: toRem(8);
    transition: 0.3s;

    &:hover{
        background: $main-gradient;
        color: $black;
    }
}