@import '@/styles/new-variables';

.moviesPanel {
  padding: 2rem 0;
  flex-grow: 1;
  position: relative;
}

.filterButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .filtersButton {
    display: flex;
    padding: 0.375rem;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid $transparent-white-10;
    transition-property: border-color color;
    transition-duration: 300ms;
    cursor: pointer;
  }

  @media (min-width: 1200px) {
    display: none;
    visibility: hidden;
  }
}
