@import '@/styles/new-variables';
@import '@/styles/functions';

$card-border-radius: 1rem;

.onlineTvCard {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid $transparent-white-6;
  border-radius: $card-border-radius;
  // backdrop-filter: blur(15px);
  transform-origin: bottom;

  .button {
    display: block;
    width: 100%;
    border: 1px solid $transparent-white-6;
    border-radius: $card-border-radius;
    backdrop-filter: blur(15px);
    transform-origin: bottom;
    cursor: pointer;
    overflow: hidden;
  }

  &.isSkelton {
    pointer-events: none;
    border-radius: toRem(8);
  }

  &.viewAllButton {
    border: none;

    .skeltonCard {
      background-color: transparent;
    }
  }

  .cardInner {
    overflow: hidden;
    position: relative;
    border-radius: inherit;
  }
}

.isPlayed {
  .cardInner::before {
    content: '';
    background: linear-gradient(180deg, rgba(15, 15, 18, 0) 0%, rgba(15, 15, 18, 0.75) 100%)
      no-repeat center/cover;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $card-border-radius;
    @media (max-width: get-breakpoint-width('sm')) {
      background: linear-gradient(180deg, rgba(15, 15, 18, 0) 0%, rgba(15, 15, 18, 0.75) 100%)
        no-repeat center/cover;
    }
  }
}

.lockIcon {
  position: absolute;
  top: toRem(12);
  left: toRem(12);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: toRem(36);
  height: toRem(36);
  border-radius: toRem(8);
  background: linear-gradient(147deg, #ffff4d -43.03%, #f46600 138.68%);
  box-shadow: 0px 8px 20px 0px rgba(251, 198, 49, 0.3);
  z-index: 1;
}

.channelLogo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.badgeContainer {
  position: absolute;
  bottom: toRem(8);
  right: toRem(8);
}

.skeltonCard {
  position: relative;
  padding-bottom: 100%;
  border-radius: 0.5rem;
  background-color: $transparent-white-6;
  color: transparent;
}

@media (max-width: get-breakpoint-width('sm')) {
  .badgeContainer,
  .lockIcon {
    bottom: 0.25rem;
    right: 0.25rem;
  }
}

.playAnimation {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: toRem(24);
  height: toRem(20);
  display: flex;
  align-items: flex-end;
  z-index: 2;
  gap: toRem(4);

  .line {
    background: $primary;
    width: toRem(3);
    border-radius: toRem(10);

    &:nth-child(1) {
      height: 40%;
      animation: firstLine 0.7s linear infinite;
      animation-direction: alternate-reverse;
    }

    &:nth-child(2) {
      height: 60%;
      animation: secondLine 0.7s linear infinite;
    }

    &:nth-child(3) {
      height: 40%;
      animation: thirdLine 0.7s linear reverse infinite;
    }
  }
}

@keyframes firstLine {
  0% {
    height: 80%;
  }
  50% {
    height: 50%;
  }
  100% {
    height: 80%;
  }
}

@keyframes secondLine {
  0% {
    height: 35%;
  }
  50% {
    height: 80%;
  }
  100% {
    height: 35%;
  }
}

@keyframes thirdLine {
  0% {
    height: 10%;
  }
  50% {
    height: 90%;
  }
  100% {
    height: 10%;
  }
}
