@import '@/styles/functions';

.slider {
  position: relative;

  .swiperWrapper {
    position: relative;
  }

  .swiperContainer {
    padding: toRem(12) toRem(15);

    @media (min-width: get-breakpoint-width('lg')) {
      padding: 1rem toRem(15);
    }
  }

  .topPanel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 toRem(15);

    @media (min-width: get-breakpoint-width('md')) {
      margin-bottom: toRem(4);
    }
  }
}
