@import '@/styles/variables';
@import '@/styles/new-variables';
@import '@/styles/functions';

.skeltonImage {
  position: relative;
  overflow: hidden;
  color: transparent;
  border-radius: toRem(16);
  padding-bottom: 49%;
  width: 100%;
  box-sizing: border-box;


  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.notificationCard {
  background: $transparent-white-6;
  border-radius: toRem(16);
  overflow: hidden;
  padding: 12px;

  .cardBody {
    padding: 1rem;
  }
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
