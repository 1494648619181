@import "@/styles/functions";
@import "@/styles/new-variables";

.metaContainer {
  margin-bottom: 2rem;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: toRem(8);
}

.paginationPanel {
  display: flex;
  justify-content: center;
  padding: 0;
}

.placeholder {
  margin-top: 2rem;
}

.desktopClearButton {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.mobileClearButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $transparent-white-10;
  border-radius: toRem(10);
  padding: toRem(6);
}

@media (min-width: get-breakpoint-width("lg")) {
  .desktopClearButton {
    display: inline-flex;
    visibility: visible;
    opacity: 1;
  }
}
