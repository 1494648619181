@import "@/styles/functions";
@import "@/styles/new-variables";

.labelIcon {
  margin-right: toRem(4);
}

.listItem {
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: toRem(-48);
      width: 100%;
      height: 1px;
      background-color: $transparent-white-6;
    }
  }
}

.dropdown {
  .dropdownItem {
    display: flex;
    align-items: center;
    gap: toRem(12);
    width: 10rem;
    padding: toRem(12) toRem(16);
    color: $transparent-white-70;
    // font-family: Inter;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    transition-property: color, background-color;
    transition-duration: 300ms;
    &>*:first-child {
      flex-shrink: 0;
    }
    &:hover {
      color: $primary;
    }
  }
}
