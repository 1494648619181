@mixin inlineFlexCenter {
  display: inline-flex;
  align-items: center;
}

.logoBase {
  @include inlineFlexCenter();
  flex-shrink: 0;

  .logoWrapper {
    position: relative;
    @include inlineFlexCenter();

    .logoIcon {
      width: 100%;
    }

    .hatImage {
      position: absolute;
      top: -.85rem;
      left: -1.3rem;
    }
  }
}
