@import "@/styles/functions";

.userBalanceWrapper {
  padding: 1rem 0 2rem;

  .userBalanceRow {
    gap: toRem(20);
  }
}

@media (min-width: get-breakpoint-width("lg")) {
  .userBalanceWrapper {
    .userBalanceRow {
      gap: 0;
    }
  }
}
