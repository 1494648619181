@import '@/styles/functions';
@import '@/styles/new-variables';

.snapshotWrapper {
  position: relative;
  overflow: hidden;
  border-radius: toRem(16);
  height: 100%;
  width: 100%;
  border: 2px solid $grey-10;
  transition: border-color 0.3s;

  &.hovered {
    &:hover {
      border: 2px solid $primary;
    }
  }
}
