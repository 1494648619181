@import "@/styles/variables";

.remainingDays {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $dark-color-1;
  background-color: $signature-color;
  border-radius: 4px;

  .remainder,
  .remainderText {
    font-weight: bold;
  }

  .remainder {
    font-size: 1.5rem;
  }

  .remainderText {
    font-size: 0.8rem;
  }

  &.reverseOrder {
    flex-direction: column-reverse;
    gap: 0.25rem 0;
  }
}

.description {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 1rem;

  .title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .dateOfExpiry {
    font-size: 0.85rem;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;

    & span {
      margin-right: 0.5rem;
      margin-bottom: 0.3rem;
      color: $light-dimmed-color-3;
    }
  }
}

@media (min-width: 425px) {
  .dateOfExpiry {
    & span {
      margin-bottom: 0;
    }
  }
}
