@import "@/styles/functions";

// 🟡 ********** Global ********** 🟡

$base-font: (var(--font-inter), sans-serif);

// 🔵 Colors 🔵
$primary: #ffff4d; // main-yellow
$white: #ffffff;
$green: #29cf29;
$alert-green: #34c763;
$yellow: #ffd633;
$black: #0f0f12;
$dark: #2d2c32;
$grey: #919191;
$grey-1: #19191b;
$grey-2: #272729;
$grey-3: #474749;
$grey-4: #878788;
$grey-5: #c5c5c5;
$grey-10: rgba(145, 145, 145, 0.1);
$red: #ff4d4d;

// 🔵 Transparent Colors 🔵

// BLACK COLORS
$transparent-black-10: rgba($black, 0.1);
$transparent-black-20: rgba($black, 0.2);
$transparent-black-40: rgba($black, 0.4);
$transparent-black-60: rgba($black, 0.6);

// WHITE COLORS
$transparent-white-5: rgba($white, 0.05); // 5% transparency
$transparent-white-6: rgba($white, 0.06); // 6% transparency
$transparent-white-10: rgba($white, 0.1); // 10% transparency
$transparent-white-15: rgba($white, 0.15); // 15% transparency
$transparent-white-30: rgba($white, 0.3); // 30% transparency
$transparent-white-40: rgba($white, 0.4); // 40% transparency
$transparent-white-50: rgba($white, 0.5); // 50% transparency
$transparent-white-60: rgba($white, 0.6); // 60% transparency
$transparent-white-70: rgba($white, 0.7); // 70% transparency

// RED COLORS
$transparent-red-10: rgba($red, 0.1);
$transparent-red-20: rgba($red, 0.2);

// YELLOW COLORS
$transparent-yellow-10: rgba($primary, 0.1);
$transparent-yellow-20: rgba($primary, 0.2);

// GREY COLORS
$transparent-grey-10: rgba($grey, 0.1);

// 🟡 ********** Gradients ********** 🟡

$main-gradient: linear-gradient(203deg, #ffff4d -1.54%, #f46600 230.02%);
$transparent-grey-20: rgba($grey, 0.6);

// 🔵 Swiper breakpoints 🔵
$swiper-gap-xs: toRem(12);
$swiper-gap-sm: toRem(12);
$swiper-gap-md: toRem(12);
$swiper-gap-lg: toRem(18);
$swiper-gap-xl: toRem(20);
$swiper-gap-xxl: toRem(20);

// 🔵 Headers 🔵
$header-z-index: 10;

$desktop-header-height: 6.25rem;
$desktop-header-bg-color: rgba(15, 15, 18, 0.9);

$mobile-header-height: 4.25rem;
$mobile-header-bg-color: $transparent-white-6;

// 🔵 Footers 🔵
$footer-font-size: 0.875rem;
$footer-font-weight: 300;

$main-footer-height: 6.25rem;
$main-footer-box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.3);

$auth-footer-height: 20vh;
$auth-footer-bg: linear-gradient(180deg, rgba(22, 21, 26, 0.0001) 0%, #16151a 99.34%);

// 🔵 Sidebar menu 🔵
$sidebar-width: 19.5rem;

// 🔵 Z-index values 🔵
$search-icon-button-z-index: 25;

// 🔵 icons 🔵
$icon-lock: "data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M8%2011V7C8%205.93913%208.42143%204.92172%209.17157%204.17157C9.92172%203.42143%2010.9391%203%2012%203C13.0609%203%2014.0783%203.42143%2014.8284%204.17157C15.5786%204.92172%2016%205.93913%2016%207V11M5%2013C5%2012.4696%205.21071%2011.9609%205.58579%2011.5858C5.96086%2011.2107%206.46957%2011%207%2011H17C17.5304%2011%2018.0391%2011.2107%2018.4142%2011.5858C18.7893%2011.9609%2019%2012.4696%2019%2013V19C19%2019.5304%2018.7893%2020.0391%2018.4142%2020.4142C18.0391%2020.7893%2017.5304%2021%2017%2021H7C6.46957%2021%205.96086%2020.7893%205.58579%2020.4142C5.21071%2020.0391%205%2019.5304%205%2019V13ZM11%2016C11%2016.2652%2011.1054%2016.5196%2011.2929%2016.7071C11.4804%2016.8946%2011.7348%2017%2012%2017C12.2652%2017%2012.5196%2016.8946%2012.7071%2016.7071C12.8946%2016.5196%2013%2016.2652%2013%2016C13%2015.7348%2012.8946%2015.4804%2012.7071%2015.2929C12.5196%2015.1054%2012.2652%2015%2012%2015C11.7348%2015%2011.4804%2015.1054%2011.2929%2015.2929C11.1054%2015.4804%2011%2015.7348%2011%2016Z%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3C/svg%3E";
