@import '@/styles/functions';
@import '@/styles/variables';
@import '@/styles/new-variables';

.categoryCard {
  .categoryCardWrapper {
    padding: 1rem;
    border-radius: toRem(11);
    border: 1px solid $transparent-white-10;
    background: $main-black;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    transition: color 300ms;
    color: $white;

    &:hover,
    &:active {
      color: $primary;
    }
  }
  .categoryBody {
    display: flex;
    align-items: center;
    gap: toRem(12);
  }

  .arrowRight {
    color: $grey-3;
  }
}
