@import "@/styles/functions";
@import "@/styles/new-variables";

.categoriesList {
    position: sticky;
    top: 20px;
    display: flex;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;
    overflow: auto;
    scrollbar-width: none;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: toRem(8);

    .scrollButton {
        cursor: pointer;
        flex: 0 0 auto;
        background: $grey-1;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.04);
        display: flex;
        align-items: center;
        justify-content: center;
        width: toRem(32);
        height: toRem(32);
        opacity: 1;
        transition: all 0.2s ease-in;

        &.hidden {
            pointer-events: none;
            transition: all 0.2s ease-in;
            opacity: 0.1;
        }
    }
}

.category {
    border-radius: toRem(40);
    border: 1px solid rgba(255, 255, 255, 0.04);
    min-height: toRem(32);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 toRem(12);
    transition: all 0.2s ease-in;
    user-select: none;
    white-space: nowrap;
    color: $transparent-white-50;
    font-size: toRem(14);
    font-weight: 600;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: toRem(4);

    &.categoryActive {
        background: $primary;
        color: $black;

        .categoryCount {
            background: rgba(15, 15, 18, 0.1);
            padding: toRem(1) toRem(5);
            border-radius: 40px;
            color: var(--Main-Black, #0f0f12);
            font-size: toRem(12);
            font-weight: 700;
            line-height: 130%;
        }
    }

    &.skeleton {
        padding: 0 55px;
        background: rgba(255, 255, 255, 0.04);
    }
}

.categoryCount {
    padding: toRem(1) toRem(5);
    border-radius: 40px;
    font-size: toRem(12);
    font-weight: 700;
    line-height: 130%;
    color: $transparent-white-50;
    background: $transparent-white-6;
}
