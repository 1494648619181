@import '@/styles/variables';

.subscriptionCardBase {
  position: relative;
  min-height: 5rem;
  display: flex;
  background-color: $dark-color-3;
  border-radius: 4px;

  .leftPanel {
    flex: 0 0 5rem;
  }

  .rightPanel {
    flex: 1 1 auto;
  }
}
