@import "@/styles/functions";
@import "@/styles/new-variables";

.noCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: toRem(344);
  margin: toRem(40) auto;

  .imageWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: toRem(16);
  }

  .title {
    color: $white;
    text-align: center;
    // font-family: "Segoe UI Variable Static Display";
    font-size: toRem(16);
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 31.2px */
  }

  .description {
    color: $transparent-white-30;
    text-align: center;
    // font-family: Inter;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    margin-bottom: 1rem;
  }
}

@media (min-width: get-breakpoint-width("sm")) {
  .noCardWrapper {
    .title {
      font-size: toRem(24);
    }

    .description {
      font-size: toRem(16);
    }
  }
}
