@import '@/styles/variables';

.sidebar {
  max-height: 100vh; /* Bu yerda vh dan foydalanishni davom ettiryapsiz */
  height: $sidebar-height;
  font-size: $sidebar-font-size;
  position: fixed;
  top: 0;
  z-index: $sidebar-z-index;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  backdrop-filter: blur(30px);
  box-shadow: 0px 10px 30px 0px rgba(15, 15, 18, 0.4);
  border-top: 0.333px solid $transparent-white-10;

  &.left{
    left: 0;
  }
  &.right{
    right: 0;
  }

  &.dark {
    background: rgba(15, 15, 18, 0.60);
    border-left: 1px solid rgba(255, 255, 255, 0.05);
  }

  &.light { 
    background: $transparent-white-6;
  }
  
}
