@import "@/styles/functions";
@import "@/styles/new-variables";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: toRem(8);
  font-weight: 600;
  line-height: toRem(24);
  transition-property: background, box-shadow, backdrop-filter, opacity;
  transition-duration: 300ms;
  cursor: pointer;

  /* Button variants */
  &.primary {
    background-color: $primary;
    color: $black;

    &.hovered {
      background-color: $yellow;
    }
  }

  &.primary-gradient {
    color: $black;
    background: linear-gradient(203deg, #ffff4d -1.54%, #f46600 230.02%);

    &.hovered {
      background: linear-gradient(155deg, #ffff4d -45.66%, #f46600 136.88%);
      box-shadow: 0px 4px 6px 0px rgba(252, 209, 54, 0.3);
    }
  }

  &.secondary {
    color: $white;
    background-color: $transparent-white-10;

    &.hovered {
      background-color: $transparent-white-30;
    }
  }

  &.secondary-red {
    color: $red !important;
    background-color: $transparent-red-10;

    &.hovered {
      background-color: $transparent-red-20;
      backdrop-filter: blur(10px);
    }
  }

  &.secondary-white {
    color: #0f0f12;
    background-color: #ffffff;

    &.hovered {
      background-color: $transparent-white-70;
      backdrop-filter: blur(10px);
    }
  }

  &.secondary-yellow {
    color: $primary;
    background: $transparent-yellow-10;
    backdrop-filter: blur(10px);

    &.hovered {
      background: $transparent-yellow-20;
    }
  }

  &.text-yellow {
    color: $primary;
    padding: 0;

    &.hovered {
      opacity: 0.8;
    }
  }

  &.outline {
    width: toRem(36);
    height: toRem(36);
    color: $white;
    border: 1px solid $transparent-white-10;
    border-radius: toRem(10);
    backdrop-filter: blur(10px);
  }

  /* Button sizes */
  &.lg {
    font-size: toRem(15);
    padding: toRem(10) toRem(32);
    border-radius: toRem(12);
  }

  &.md {
    font-size: toRem(14);
    padding: toRem(6) toRem(24);
    border-radius: toRem(10);
  }

  &.sm {
    font-size: toRem(12);
    padding: toRem(4) toRem(16);
    border-radius: toRem(8);
  }

  &.xs {
    font-size: inherit;
    padding: toRem(6);
    border-radius: toRem(10);
  }

  /* Button shapes */

  &.circle {
    padding: 0.375rem;
    width: toRem(32);
    height: toRem(32);
    border-radius: 50%;
  }

  &.round {
    border-radius: toRem(40);
  }

  /* Button border */

  &.bordered {
    border: toRem(1) solid $transparent-yellow-10;
  }

  /* Button disabled */
  &.disabled,
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}
