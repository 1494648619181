@import '@/styles/functions';
@import '@/styles/new-variables';

$image-radius: calc(1rem - 2px);

.suggestion {
  position: relative;
  height: 100%;
  border-radius: 1rem;
  background: $black;
  backdrop-filter: blur(15px);
  border: 2px solid $transparent-grey-10;

  &.highlighted {
    border-color: $primary;
  }

  .movieCardBody {
    position: absolute;
    z-index: 9;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, #0f0f12 0%, rgba(15, 15, 18, 0) 100%);
    padding: toRem(12);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.movieImage {
  border-radius: $image-radius;
}
