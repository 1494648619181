@import '@/styles/functions';
@import '@/styles/new-variables';

$sllipse-shadow: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="857" height="567" viewBox="0 0 857 567" fill="none"><g opacity="0.4" filter="url(%23filter0_f_12163_24302)"><path d="M686.607 -94.0363C793.377 92.3109 467.462 221.488 347.164 352.018C226.865 482.548 62.1292 620.018 33.5374 593.667C4.94554 567.317 35.8971 377.09 391.418 368.843C543.048 332.126 528.646 -198.882 686.607 -94.0363Z" fill="%23FFFF4D"/></g><defs><filter id="filter0_f_12163_24302" x="-123.142" y="-256.276" width="979.468" height="1001.78" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="74.2792" result="effect1_foregroundBlur_12163_24302"/></filter></defs></svg>');

@mixin shadowBackground {
  background-image: $sllipse-shadow;
  background-size: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 40% bottom 45%;
}

.randomMovieWrapper {
  display: flex;
  background-image: url(/assets/images/movies-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: toRem(24);
  overflow: hidden;

  .movieTitle {
    color: $white;
    font-size: toRem(28);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: toRem(12);
  }

  .movieDetails {
    display: none;

    .movieInfo {
      .description {
        color: $white;
        font-size: toRem(14);
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 18.2px */
      }

      .detailsInfoWrapper {
        padding: toRem(20) 0;

        li {
          display: flex;
          justify-content: space-between;

          &:not(:last-child) {
            border-bottom: 1px solid $grey-3;
            margin-bottom: toRem(6);
            padding-bottom: toRem(6);
          }

          .label {
            font-size: toRem(16);
            font-weight: 400;
            line-height: toRem(22);
            text-align: left;
            color: $grey-5;
            margin-right: 1rem;
          }

          .linkBtnWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: toRem(8);
            white-space: normal;
            flex-wrap: wrap;

            .linkBtn {
              padding: toRem(4) toRem(6);
              border-radius: toRem(6);
              background-color: $grey-2;
              font-size: toRem(16);
              font-weight: 500;
              line-height: toRem(22);
              color: #d9d9d9;
              transition: background-color 300ms;

              &:hover {
                background-color: $transparent-white-30;
              }
            }

            .infoText {
              font-size: toRem(16);
              font-weight: 500;
              line-height: toRem(22);
              color: #d9d9d9;
              &.capitalized {
                text-transform: capitalize;
              }
            }
          }

          .ratingWrapper {
            display: flex;
            align-items: center;
            gap: toRem(20);

            .rating {
              display: flex;
              align-items: center;
              gap: toRem(4);
            }
          }
        }
      }
    }
  }

  .movieImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
    padding: toRem(20) toRem(16);
    @include shadowBackground();
  }

  .movieImage {
    border-radius: toRem(20);
    cursor: pointer;
    user-select: none;
  }

  .movieDetailsMobile {
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(15, 15, 18, 0) 0%, rgba(15, 15, 18, 0.9) 100%);
  }
}

@media (min-width: get-breakpoint-width('md')) {
  .randomMovieWrapper {
    .movieDetails {
      display: flex;
      flex: 3;
      flex-direction: column;
      justify-content: space-between;
      padding: toRem(20) toRem(41) toRem(20) toRem(20);
      background: $grey-1;
      @include shadowBackground();

      .movieInfo {
        .detailsInfoWrapper {
          li {
            &:not(:last-child) {
              margin-bottom: toRem(16);
              padding-bottom: toRem(16);
            }
          }
        }
      }
    }

    .movieImageWrapper {
      padding: toRem(30) toRem(32);
    }

    .movieDetailsMobile {
      display: none;
    }
  }
}

@media (min-width: get-breakpoint-width('lg')) {
  .randomMovieWrapper {
    .movieDetails {
      .movieInfo {
        .detailsInfoWrapper {
          li {
            &:not(:last-child) {
              margin-bottom: toRem(16);
              padding-bottom: toRem(16);
            }
          }
        }
      }
    }
  }
}

@media (min-width: get-breakpoint-width('xl')) {
  .randomMovieWrapper {
    .movieDetails {
      flex: 9;
    }

    .movieTitle {
      font-size: toRem(40);
      font-weight: 600;
      margin-bottom: 1rem;
    }
    .movieImageWrapper {
      flex: 5;
      padding: toRem(30) toRem(56);
    }
    .movieDetails {
      padding: toRem(30) toRem(73) toRem(30) toRem(30);

      .movieInfo {
        .description {
          font-size: toRem(14);
          font-weight: 500;
        }
      }
    }
  }
}
