.fadeEnter {
  opacity: 0;
}

.fadeEnterActive {
  opacity: 1;
  transition: opacity 0.2s;
}

.fadeExit {
  opacity: 1;
}

.fadeExitActive {
  opacity: 0;
  transition: opacity 0.15s;
}
