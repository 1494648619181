@import '@/styles/new-variables';
@import '@/styles/functions';

$card-border-radius: 1rem;

.onlineTvCard {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid $transparent-white-6;
  border-radius: $card-border-radius;
  transform-origin: bottom;

  &.viewAllButton {
    border: none;

    .skeltonCard {
      position: relative;
      position: relative;
      padding-bottom: 100%;
      border-radius: 0.5rem;
      color: transparent;
      background-color: transparent;
    }
  }
}
