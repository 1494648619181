@import "@/styles/functions";

.loaderButtonWrapper {
  display: flex;
  justify-content: center;
}

.loaderButton {
  min-width: toRem(202);
  width: fit-content;
}
