@import '@/styles/variables';
@import '@/styles/functions';

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .cardsPanel {
    flex-grow: 1;
  }

  .placeholder {
    margin-top: 2rem;
  }

  .sectionHeading {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}

.errorText {
  font-size: $form-input-error-font-size;
  color: $form-input-error-text-color;
}
