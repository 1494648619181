@import '@/styles/new-variables';

$base-font: (var(--font-inter), sans-serif);

// 🟡 ********** Global ********** 🟡

$main-black: #0f0f12;
$main-white: #ffffff;
$main-green: #29cf29;
$main-yellow: #ffff4d;

$transparrent-white-A06: rgba($main-white, 0.06);
$transparrent-white-A10: rgba($main-white, 0.1);
$transparrent-white-A50: rgba($main-white, 0.5);
$transparrent-white-A30: rgba($main-white, 0.3);

$overlay-bg-card: linear-gradient(180deg, rgba(15, 15, 18, 0.1) 0%, $main-black 100%);

// 🔵 Colors 🔵
$main-black: #0f0f12;

$dark-color-1: #0f0f12;
$dark-color-2: #000;
$dark-color-3: #2d2c32;
$dark-color-4: #111014;

$dark-color-1-A75: rgba($dark-color-1, 0.75);
$dark-color-1-A90: rgba($dark-color-1, 0.9);

$dark-color-3-L10: lighten($dark-color-3, 10%);
$dark-color-3-L40: lighten($dark-color-3, 40%);

$light-color-1: #fff;
$light-color-1-D30: darken($light-color-1, 30);

$light-color-1-A20: rgba($light-color-1, 0.2);
$light-color-1-A50: rgba($light-color-1, 0.5);
$light-color-1-A75: rgba($light-color-1, 0.75);

$light-dimmed-color-1: rgba($light-color-1, 0.8);
$light-dimmed-color-2: rgba($light-color-1, 0.7);
$light-dimmed-color-3: rgba($light-color-1, 0.6);
$light-dimmed-color-4: rgba($light-color-1, 0.5);
$light-dimmed-color-5: rgba($light-color-1, 0.3);

$signature-color: #ffff4d;
$danger-color: #ff2e2e;

// 🔵 Badges and icons 🔵
$free-badge-bg-color: #008000;
$new-badge-bg-color: $danger-color;
$quality-badge-bg-color: #0093ff;
$favorite-icon-active-bg-color: $danger-color;

$image-box-shadow: (
  0 0.1rem 0.1rem rgba($dark-color-2, 0.2),
  0 0.3rem 0.3rem rgba($dark-color-2, 0.2),
  0 0.5rem 0.5rem rgba($dark-color-2, 0.2),
  0 0.7rem 0.7rem rgba($dark-color-2, 0.2)
);

$dashed-border: 1px dashed $light-dimmed-color-5;

// 🟡 ********** Layout parts ********** 🟡

$auth-container-width: 95%;

// 🔵 Headers 🔵
$header-z-index: 10;

$desktop-main-header-height: 4.375rem;
$desktop-main-header-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
$desktop-main-header-font-size: 0.875rem;
$desktop-main-header-font-weight: 600;

$mobile-main-header-height: 3.5rem;
$mobile-main-header-box-shadow: $desktop-main-header-box-shadow;
$mobile-main-header-font-size: $desktop-main-header-font-size;
$mobile-main-header-font-weight: $desktop-main-header-font-weight;

$auth-header-min-height: 6.25rem;
$auth-header-height: 20vh;
$auth-header-bg: linear-gradient(0deg, rgba(22, 21, 26, 0.0001) 0%, #151419 100%);

// 🔵 Footers 🔵
$footer-font-size: 0.875rem;
$footer-font-weight: 300;

$main-footer-height: 6.25rem;
$main-footer-box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.3);

$auth-footer-height: 20vh;
$auth-footer-bg: linear-gradient(180deg, rgba(22, 21, 26, 0.0001) 0%, #16151a 99.34%);

// 🔵 Sidebar menu 🔵
$sidebar-bg-color: $dark-color-1;
$sidebar-font-size: 0.875rem;
$sidebar-width: 19.5rem;
$sidebar-height: 100%;
$sidebar-box-shadow: 8px 0px 10px rgba(0, 0, 0, 0.3);
$sidebar-z-index: 30;

// 🟡 ********** Components ********** 🟡

// 🔵 Header panel 🔵
$header-panel-bg-color: rgba($dark-color-3, 0.5);

// 🔵 Backdrop 🔵
$backdrop-bg-color: rgba($dark-color-2, 0.9);
$backdrop-z-index: 20;

// 🔵 Modal 🔵
$modal-bg-color: $dark-color-4;
$modal-z-index: 21;

// 🔵 Search bar 🔵
$searchbar-font-size: 1rem;
$searchbar-font-weight: 600;
$searchbar-padding-left: 1.5rem;
$searchbar-padding-right: 1.5rem;
$searchbar-input-padding: 0.5rem 1rem;
$searchbar-bg-color: $dark-color-3;
$searchbar-z-index: 25;

// 🔵 Suggested list 🔵
$suggested-list-bg-color: $searchbar-bg-color;
$suggested-list-search-results-button-bg-color: #1d1c20;

// 🔵 Buttons 🔵
$solid-button-font-weight: 600;
$solid-button-desktop-font-size: 0.875rem;
$solid-button-desktop-padding: 0.5rem 1.5rem;
$solid-button-mobile-font-size: 0.8125rem;
$solid-button-mobile-padding: 0.3rem 1rem;
$solid-button-signature-text-color: $dark-color-1;
$solid-button-signature-bg-color: $signature-color;
$solid-button-dimmed-text-color: $light-color-1;
$solid-button-dimmed-bg-color: $dark-color-4;

$text-button-desktop-font-size: 0.875rem;
$text-button-mobile-font-size: 0.8125rem;
$text-button-text-color: $light-dimmed-color-2;

$search-icon-button-z-index: 25;
$burger-icon-button-z-index: 25;

// 🔵 Badges 🔵
$cinerama-id-badge-padding: 0.05rem 0.3rem;
$cinerama-id-badge-bg-color: #eee;

// 🔵 Auth forms 🔵
$form-desktop-width: 40rem;
$form-mobile-width: 35rem;
$form-content-desktop-width: 33rem;
$form-content-mobile-width: 100%;

$form-title-desktop-font-size: 3.5rem;
$form-title-mobile-font-size: 2.5rem;
$form-title-font-weight: 750;

$form-alert-font-weight: $solid-button-font-weight;
$form-alert-desktop-font-size: $solid-button-desktop-font-size;
$form-alert-desktop-padding: $solid-button-desktop-padding;
$form-alert-mobile-font-size: $solid-button-mobile-font-size;
$form-alert-mobile-padding: $solid-button-mobile-padding;
$form-alert-warning-bg-color: $signature-color;
$form-alert-warning-text-color: $solid-button-signature-text-color;
$form-alert-error-bg-color: $danger-color;
$form-alert-error-text-color: $light-color-1;

$form-input-font-size: 1rem;
$form-input-text-color: $light-color-1;
$form-input-label-color: $light-dimmed-color-2;
$form-input-border-color: rgba($light-color-1, 0.2);
$form-input-error-font-size: 0.8125rem;
$form-input-error-text-color: $danger-color;

$form-checkbox-label-desktop-font-size: 0.875rem;
$form-checkbox-label-mobile-font-size: 0.8125rem;
$form-checkbox-label-color: $form-input-label-color;

// 🔵 Icons 🔵
