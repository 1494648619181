@import '@/styles/functions';
@import '@/styles/new-variables';

.randomMovieSection {
  margin-bottom: 2rem;

  .title {
    margin-bottom: toRem(24);
  }
}
