@import "@/styles/functions";
@import "@/styles/new-variables";

.progressBar {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
  padding: 0 0.5rem;

  .progress {
    width: 100%;
    height: 0.25rem;
    position: relative;
    overflow: hidden;
    background: $transparent-white-10;
    border-radius: 2.5rem;
  }

  .progressInner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.light .progressInner {
    background-color: $white;
  }

  &.primary .progressInner {
    background-color: $primary;
  }

  &.signature .progressInner {
    background-color: $green;
  }

  .time {
    display: flex;
    justify-content: flex-end;

    .timer {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
      color: $white;
    }
  }
}
