@import "@/styles/variables";
@import "@/styles/functions";

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 1rem;

  @media (max-width: get-breakpoint-width("lg")) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: get-breakpoint-width("md")) {
    grid-template-columns: repeat(4, 1fr);
    gap: toRem(8);
  }
  @media (max-width: get-breakpoint-width("sm")) {
    grid-template-columns: repeat(3, 1fr);
  }
}
