@import "@/styles/functions";
@import "@/styles/new-variables";

.favoriteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: toRem(36);
  height: toRem(36);
  border-radius: 0.625rem;
  background: $transparent-white-10;
  transition: transform 0.3s;
  backdrop-filter: blur(25px);

  & > svg {
    margin-right: 0;
  }

  &.active {
    color: $primary;
  }

  &:hover {
    transform: scale(1.1);
  }

  .spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
