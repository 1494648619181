@import "@/styles/functions";
@import "@/styles/new-variables";

$icon-size: toRem(90);
$icon-mobile-size: toRem(60);

.homeGenreCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: toRem(19);
  display: flex;
  padding: toRem(12);
  flex-shrink: 0;
  border-radius: toRem(20);
  background: $transparent-white-6;

  .genreIcon {
    width: $icon-mobile-size;
    height: $icon-mobile-size;
  }

  .genreTitle {
    display: -webkit-box;
    width: 100%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    align-self: stretch;
    text-overflow: ellipsis;
    color: $white;
    text-align: center;
    text-overflow: ellipsis;
    // font-family: Inter;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    transition: color 300ms;
  }

  &:hover {
    .genreTitle {
      color: $primary;
    }
  }

  &.isSkelton {
    pointer-events: none;
  }

  &.viewAllButton {
    background: $black;

    .genreTitle {
      opacity: 0;
      visibility: hidden;

      &::before {
        content: "view all";
      }
    }
  }
}

@media (min-width: get-breakpoint-width("sm")) {
  .homeGenreCard {
    padding: toRem(16) toRem(16) toRem(26) toRem(16);

    .genreIcon {
      width: $icon-size;
      height: $icon-size;
    }

    .genreTitle {
      font-size: 1rem;
    }
  }
}
