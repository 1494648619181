@import "@/styles/functions";

.paymeContainer {
  padding: 1.5rem 0 1rem;
}

.addBankCardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
