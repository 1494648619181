@import "@/styles/functions";
@import "@/styles/new-variables";

.date{
    color: $grey-3;
    font-weight: 500;
    line-height: 130%; 
    margin-bottom: toRem(12);
}

.notificationCard{
    position: relative;
    cursor: pointer;
    display: block;
    border-radius: 16px;
    background: var(--transparrent-white-white-6, rgba(255, 255, 255, 0.06));
    padding: toRem(12);
    transition: all 0.3s ease;


    &.isRead{
        background: $transparent-yellow-10;
    }
    
    .badge{
        position: absolute;
        top: toRem(15);
        left: toRem(15);
    }
    .imageContainer{
        position: relative;
        border-radius: toRem(24);
        overflow: hidden;
        margin-bottom: toRem(10);
        min-height: 180px;

        .time{
            position: absolute;
            bottom: toRem(8);
            left: toRem(8);
            color: var(--transparrent-white-white-50, rgba(255, 255, 255, 0.50));
            font-size: toRem(12);
            line-height: 130%;
        }

        .skeltonImage{
            object-fit: cover;
        }

    }

    .cardImage{
        width: 100%;
        border-radius: toRem(20);
        position: relative;
    }
    
    .title{
        color: white;
        font-size: toRem(16);
        font-weight: 600;
        line-height: 130%;
        margin-bottom: toRem(4);
    }

    .description{
        color: $transparent-white-50;
        font-size: toRem(14);
        line-height: 130%; 
    }

}