@import '@/styles/new-variables';

.secondarySubscription {
  border-radius: 12px;
  background: $grey-1;
  padding: 12px;
  display: flex;
  gap: 12px;

  .iconWrapper {
    display: flex;
    width: 41px;
    height: 40px;
    padding: 8px 8px 8px 9px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));
  }

  .mainInfo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    .title {
      color: $white;
      font-size: 14px;
      font-weight: 700;
      line-height: 130%; /* 18.2px */
    }
  }
  .subtitle {
    color: $white;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
  }
  .description {
    color: var(--transparrent-white-white-30, rgba(255, 255, 255, 0.3));
    font-size: 12px;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
  }

  .timeInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
  }
}
