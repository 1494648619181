@import '@/styles/variables';

.topPanel {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: toRem(24);
}

.description {
  color: $light-color-1-A50;
  margin-top: 0.5rem;
}

.bottomPanel {
  display: flex;
}

@media (min-width: 768px) {
  .paginationTop {
    margin-left: auto;
    margin-top: 0;
  }

  .paginationBottom {
    margin: 0 0 0 auto;
  }
}
