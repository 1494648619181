@import '@/styles/variables';
@import '@/styles/functions';

.modalBody {
  font-size: 0.9375rem;
}

.errorMessage {
  padding-top: 0.25rem;
}

.text {
  margin-bottom: 1.5rem;
}

.input {
  margin-bottom: 0.5rem;
}

.addCardButton {
  margin-top: 1rem;
  width: 100%;
}

.title {
  color: $white;
  text-align: center;
  font-size: toRem(20);
  font-weight: 600;
  line-height: 130%; /* 26px */
  margin-bottom: toRem(4);
}

.description {
  color: $transparent-white-30;
  text-align: center;
  font-size: toRem(14);
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}

.modalHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .responseMessage {
    margin: 1rem auto 0;
    display: inline-flex;
    padding-left: toRem(12);
    justify-content: center;
    align-items: center;
    gap: toRem(10);
    border-radius: toRem(8);
    overflow: hidden;
    background: $grey-2;
    color: $white;
    font-size: 12px;
    font-weight: 600;
    line-height: 130%; /* 15.6px */

    .iconWrapper {
      display: flex;
      padding: 6px;
      align-items: center;
      gap: 10px;
      background: $transparent-white-10;
    }
  }
}

.codeInput {
  padding: 2rem 0;
}

.resetButton {
  margin: 0 auto;
  display: flex;
  gap: toRem(8);
  color: $grey-5;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 130%; /* 20.8px */

  .timer {
    display: flex;
    height: 24px;
    padding: 4px 6px;
    align-items: center;
    gap: 10px;
    border-radius: toRem(6);
    background: $grey-2;
    color: $white;
  }
}
