@import '@/styles/variables';
@import '@/styles/functions';

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .cardsPanel {
    flex-grow: 1;
  }
}
.placeholder {
  margin-top: 2rem;
}
