.metaContainer {
  margin-bottom: 2rem;
}

.paginationPanel {
  display: flex;
  justify-content: center;
  padding: 0;
}

.placeholder {
  margin-top: 2rem;
}
