@import "@/styles/functions";
@import "@/styles/new-variables";

.container{
    position: relative;
    display: block;
    max-width: toRem(409);
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 0.333px solid $transparent-white-10;

    .headerLeft{
        display: flex;
        align-items: center;
        gap: toRem(10);
    }

    .closeButton{
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0.333px solid $transparent-white-10;
        background: $transparent-white-10;
        box-shadow: 0px 10px 30px 0px rgba(15, 15, 18, 0.40);
        backdrop-filter: blur(30px);
        width: toRem(20);
        height: toRem(20);
        border-radius: 50%;
    }
}


