@import "@/styles/functions";

.sectionHeading {
    margin: toRem(24) 0 toRem(20);
}

.categoryList {
    margin-bottom: toRem(20);
}

@media (max-width: get-breakpoint-width("lg")) {
    .sectionHeading {
        margin-bottom: toRem(18) 0 toRem(10);
    }
}
