@import "@/styles/functions";
@import "@/styles/new-variables";

.actorsWrapper {
  margin-bottom: toRem(20);

  .actorRole {
    color: $primary;
    // font-family: Inter;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    text-transform: capitalize;
    margin-bottom: toRem(12);
  }
}
