@import "@/styles/functions";
@import "@/styles/new-variables";

.actorCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: toRem(46) 1rem 1rem;
  background-color: $grey-1;
  color: $white;
  border-radius: 1rem;
  transition: color 300ms;
  margin-top: toRem(34);

  &:hover {
    color: $primary;
  }

  .actorRole {
    color: $grey-4;
    text-align: center;
    // font-family: Inter;
    font-size: toRem(11);
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 14.3px */
    margin-bottom: toRem(4);
  }

  .actorName {
    text-align: center;
    // font-family: Inter;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }

  .imageWrapper {
    position: absolute;
    top: -35%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100%;
    overflow: hidden;
    width: toRem(68);
    height: toRem(68);
    border: 1px solid $grey-10;
    backdrop-filter: blur(15px);
  }
}
