@import '@/styles/variables';
@import '@/styles/new-variables';
@import '@/styles/functions';

.searchCategoryContainer {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;

  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 25;

  .searchInput {
    width: 100%;
    height: 2.75rem;
    display: flex;
    padding: 0.625rem 10.625rem 0.625rem 0.625rem;
    align-items: flex-end;
    gap: 0.5rem;

    border-radius: 0.75rem;
    border: 1px solid var(--Greys-Grey-2, #272729);
    background: $grey-1;
  }

  .categoryButton {
    position: relative;
    height: 2.75rem;
    width: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid $transparent-white-10;
    transition-property: color, border-color;
    transition-duration: 300ms;

    &.active {
      color: $signature-color;
      border-color: $primary;
    }

    &:hover {
      color: $signature-color;
      border-color: $primary;
    }
  }
}
