@import "@/styles/functions";
@import "@/styles/new-variables";

.cardWrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
  border: 2px solid $transparent-white-6;
  transition: border-color 300ms;

  &.hovered {
    border-color: $primary;
  }

  .cardOverlayWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-end;

    .trashbinIcon {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $red;
      }
    }

    .cardControlPanel {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .controlWrapper {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .playBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.75rem;
          height: 2.75rem;
          padding: 0.625rem;
          border-radius: 50%;
          opacity: 1;
          background: $transparent-white-10;
          backdrop-filter: blur(15px);
          transition: opacity 300ms;
        }
      }
    }
  }

  .cardFooter {
    position: relative;
    padding: toRem(4) 0.5rem;

    .cardSubtitle {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.skeltonImageWrapper {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  color: transparent;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
  }
}

@media (min-width: get-breakpoint-width("xl")) {
  .cardWrapper {
    .cardOverlayWrapper {
      .cardControlPanel {
        .controlWrapper {
          .playBtn {
            opacity: 0;
          }
        }
      }

      &:hover {
        .cardControlPanel {
          .controlWrapper {
            .playBtn {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
