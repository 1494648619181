.slideEnter {
  transform: translateX(-100%);
}

.slideEnterActive {
  transform: translateX(0);
  transition: transform 0.3s 0.15s;
}

.slideExit {
  transform: translateX(0);
}

.slideExitActive {
  transform: translateX(-100%);
  transition: transform 0.1s;
}
