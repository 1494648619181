@import '@/styles/variables';

.filledIconButtonBase {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  border-radius: 0.625rem;
  background: var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(25px);

  & > svg {
    margin-right: 0;
  }

  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
}
