@import "@/styles/variables";

.card {
  display: block;
  width: 100%;
  position: relative;
  text-align: inherit;
  transition: color 0.2s;
  color: $light-dimmed-color-1;

  &:hover {
    color: $light-color-1;
  }
}
