@import '@/styles/variables';
@import '@/styles/functions';

.uzCard {
  border-radius: 10px;
  background-color: $dark-color-3;
  overflow: hidden;
  font-size: 0.9375rem;

  &Content {
    padding: 1.5rem;

    @media (min-width: get-breakpoint-width('lg')) {
      padding: 2rem;
    }
  }

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    background-color: #d9d9d9;
  }
}

.cardModalHeader {
  color: $white;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  border-bottom: 1px solid $transparent-white-6;
}
