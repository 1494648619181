@import "@/styles/new-variables";

.secondaryNav {
  flex: 0 0 auto;
  display: flex;
  margin-left: auto;
  align-items: center;
  gap: 0.75rem;

  .navButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(203deg, #ffff4d -1.54%, #f46600 230.02%);

    & > span {
      color: $black;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.5rem; /* 171.429% */
    }
  }
}

.searchTogglerContainer {
  display: flex;
  z-index: $search-icon-button-z-index;
}


