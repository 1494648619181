@import '@/styles/functions';
@import '@/styles/new-variables';

.tvFavoriteButton {
  position: absolute;
  top: toRem(4);
  right: toRem(4);
  display: flex;
  align-items: center;
  justify-content: center;
  width: toRem(36);
  height: toRem(36);
  border-radius: 8px;
  background: $transparent-black-40;
  transition: opacity 0.3s ease-in;
  // backdrop-filter: blur(5px);
  // -webkit-backdrop-filter: blur(5px);
  z-index: 1;

  &.active {
    color: $primary;
  }

  &.disabled {
    opacity: 0.6;
  }
}

@media (min-width: get-breakpoint-width('sm')) {
  .tvFavoriteButton {
    top: toRem(8);
    right: toRem(8);
  }
}
