@import "@/styles/variables";

.collectionCard {
  display: block;
  width: 100%;
  position: relative;
  text-align: inherit;
  transition: color 0.2s;
  color: $light-dimmed-color-1;

  &:hover {
    color: $light-color-1;
  }
}

.imageContainer {
  position: relative;
}

.stackImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
  transition: filter 0.2s;
}

.stackImageRear {
  width: 80%;
  top: -12px;
  opacity: 0.3;
  transform: translateX(12%);
}

.stackImageMiddle {
  width: 90%;
  top: -6px;
  transform: translateX(5%);
  opacity: 0.4;
}

.cardImageHover {
  transform-origin: bottom;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: scale(1.07);
  }
}

.skeltonImageWrapper {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  color: transparent;

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
