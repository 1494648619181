@import '@/styles/functions';
@import '@/styles/new-variables';

.bannerSliderWrapper {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2rem;

  .bannerTogglePrev,
  .bannerToggleNext {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20%;
    z-index: 3;

    .bannerToggleBtn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: hidden;
      visibility: hidden;
      align-items: center;
      justify-content: center;
      width: toRem(44);
      height: toRem(44);
      border-radius: 50%;
      background-color: $transparent-white-10;
    }
  }

  .bannerTogglePrev {
    left: 0;
    // background: linear-gradient(to right, #0f0f12 0%, rgba(15, 15, 18, 0.4) 95%, transparent);

    .bannerToggleBtn {
      right: 5%;
    }
  }

  .bannerToggleNext {
    right: 0;
    // background: linear-gradient(to left, #0f0f12 0%, rgba(15, 15, 18, 0.4) 95%, transparent);

    .bannerToggleBtn {
      left: 5%;
    }
  }
}

@media (min-width: get-breakpoint-width('md')) {
  .bannerSliderWrapper {
    .bannerTogglePrev,
    .bannerToggleNext {
      width: 22%;
    }
  }
}

@media (min-width: get-breakpoint-width('lg')) {
  .bannerSliderWrapper {
    margin-top: toRem(20);
    margin-bottom: toRem(36);
  }
}

@media (min-width: get-breakpoint-width('xl')) {
  .bannerSliderWrapper {
    .bannerTogglePrev,
    .bannerToggleNext {
      .bannerToggleBtn {
        display: flex;
        visibility: visible;
      }
    }
  }
}
