@import "@/styles/variables";

$overlay-radius: calc(1rem - 2px);

.cardOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(15, 15, 18, 0.1) 0%, #0f0f12 100%);
  border-radius: $overlay-radius $overlay-radius 0 0;
  text-align: inherit;
}
