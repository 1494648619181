@import "@/styles/variables";
@import "@/styles/functions";

.promocodeSection {
  display: block;
  margin-bottom: 2rem;

  @media (max-width: get-breakpoint-width("lg")) {
    margin-bottom: 1rem;
  }

  .wrapper {
    position: relative;
    border-radius: toRem(24);
    overflow: hidden;
    // background: #b91b34;

    &.dark {
      background: $grey-1;
    }

    .imagePromocode {
      position: absolute;
      bottom: 0;
      right: 0;
      object-fit: cover;
      object-position: bottom right;
    }

    @media (max-width: get-breakpoint-width("lg")) {
      padding-bottom: toRem(47);
    }

    .content {
      position: relative;
      padding: toRem(30);
      @media (max-width: 991px) {
        padding: toRem(16);
      }

      .title {
        font-size: toRem(24);
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        max-width: toRem(393);
        margin-bottom: toRem(20);
        span {
          display: block;
        }
        @media (max-width: get-breakpoint-width("lg")) {
          font-size: toRem(20);
          margin-bottom: toRem(24);
          span {
            display: inline;
          }
        }
      }

      .items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: toRem(326);
        column-gap: toRem(44);
        row-gap: toRem(20);
        margin-bottom: toRem(40);
        @media (max-width: get-breakpoint-width("lg")) {
          column-gap: toRem(33);
          margin-bottom: toRem(24);
        }

        .item {
          .count {
            color: $main-yellow;
            font-size: toRem(24);
            font-weight: 900;
            line-height: 125%;
            text-transform: uppercase;
            @media (max-width: get-breakpoint-width("lg")) {
              font-size: toRem(20);
            }
          }
          .label {
            white-space: nowrap;
            color: #fff;
            font-size: toRem(14);
            font-style: normal;
            font-weight: 600;
            line-height: 125%;
            text-transform: uppercase;
            @media (max-width: 375px) {
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
