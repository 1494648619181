@import "@/styles/functions";
@import "@/styles/new-variables";

$image-radius: calc(1rem - 2px);

.movieCard {
  position: relative;
  height: 100%;
  border-radius: 1rem;
  background: $black;
  backdrop-filter: blur(15px);
  border: 2px solid $transparent-grey-10;

  .movieCardBody {
    padding: 1rem;
  }
}

.movieImage {
  border-radius: $image-radius $image-radius 0 0;
}

.movieCardOverlay {
  background: linear-gradient(180deg, rgba(15, 15, 18, 0.6) 0%, rgba(15, 15, 18, 0.88) 49.54%, #0f0f12 100%);
}

.cardBody {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;

  .cardBodyTop {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
  }

  .cardBodyFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .rating {
      padding-bottom: 0.25rem;
    }

    .subtitle {
      padding-top: 0.5rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      align-self: stretch;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $grey-4;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 130%; /* 0.975rem */
    }
  }
}

.imageContainer {
  position: relative;
}

.stackImage {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.625rem;
  object-fit: cover;
  transition: filter 0.2s;
  z-index: -1;
}

.stackImageRear {
  width: 80%;
  top: -12px;
  transform: translateX(12%);
  opacity: 0.3;
}

.stackImageMiddle {
  width: 90%;
  top: -6px;
  transform: translateX(5%);
  opacity: 0.4;
}

.freeBadgeWrapper {
  position: absolute;
  top: 1rem;
  left: 0;
}

.newBadgeWrapper {
  position: absolute;
  bottom: 1rem;
  left: 0;
}

.contentOriginBadgeContainer {
  position: absolute;
  top: 0;
  left: 0.5rem;
}

.contentOriginBardgeMoreTv {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.ageLimit {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
  background-color: rgba(48, 48, 48, 0.3);
  font-size: 0.8rem;
  font-weight: 600;
}
