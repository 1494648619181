@import '@/styles/functions';
@import '@/styles/new-variables';

$gap: toRem(12);

.trailersSliderWrapper {
  margin-top: toRem(12);

  @media (min-width: get-breakpoint-width('md')) {
    margin-top: toRem(16);
  }
}
.trailersSlide {
  margin-right: $gap;
  max-width: calc((100% - $gap) / 2);

  @media (min-width: get-breakpoint-width('md')) {
    max-width: calc((100% - 2 * $gap) / 3);
  }

  @media (min-width: get-breakpoint-width('lg')) {
    margin-right: toRem(18);
    max-width: calc((100% - 2 * toRem(18)) / 3);
  }

  @media (min-width: get-breakpoint-width('xl')) {
    margin-right: toRem(20);
    max-width: calc((100% - 3 * toRem(20)) / 4);
  }
}

.sectionHeading {
  display: flex;
  align-items: center;
  gap: toRem(8);

  .icon {
    color: $grey-4;
  }
}
